import PlacesAutocomplete from 'stimulus-places-autocomplete'

export default class extends PlacesAutocomplete {
    connect() {
        super.connect()
        // The google.maps.places.Autocomplete instance.
        // (this.autocomplete)
    }

    // You can override the `initAutocomplete` method here.
    initAutocomplete() {
        super.initAutocomplete()
    }

    // You can override the `placeChanged` method here.
    placeChanged() {
        super.placeChanged()
        console.log(this.place)
        let lat, lng;
        if (this.place.geometry !== void 0) {
            lng = this.place.geometry.location.lng().toString();
            lat = this.place.geometry.location.lat().toString();
        }

        axios.post('/add-location', {
            place_id: this.place.place_id,
            address_components: this.place.address_components,
            lat,
            lng
        }).then((response) => {
            window.Livewire.emit('refresh-budget-form');
            window.Livewire.emit('refresh-navigation-menu');
        }).catch((error) => {
            console.log(error);
        });
    }

    get autocompleteOptions() {
        return {
            fields: ["address_components", "geometry", "place_id"],
            types: ['(regions)'],
            componentRestrictions: {
                country: this.countryValue
            }
        };
    }
}

import 'alpine-turbo-drive-adapter'

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
// import FormsAlpinePlugin from '../../../vendor/filament/forms/dist/module.esm'
// import NotificationsAlpinePlugin from '../../../vendor/filament/notifications/dist/module.esm'
import intersect from '@alpinejs/intersect'


window.Alpine = Alpine

// Alpine.plugin(FormsAlpinePlugin)
// also on app.css ../../vendor/filament/forms/dist/module.esm.css
// Alpine.plugin(NotificationsAlpinePlugin)

Alpine.plugin(focus)
Alpine.plugin(intersect)

Alpine.store('events', {
    send(name) {
        // send event
    },
})

Alpine.start()

/*function initAlpineTurboPermanentFix() {
    document.addEventListener('turbo:before-render', () => {
        let permanents = document.querySelectorAll('[data-turbo-permanent]')
        let undos = Array.from(permanents).map(el => {
            el._x_ignore = true
            return () => {
                delete el._x_ignore
            }
        })

        document.addEventListener('turbo:render', function handler() {
            while (undos.length) undos.shift()()
            document.removeEventListener('turbo:render', handler)

        })
    })
}

initAlpineTurboPermanentFix()*/
